.page--contacts {
    #container {
        position: relative;
        padding-top: 1px;
        padding-bottom: 6rem;
        margin-bottom: 0;
        @include media-breakpoint-up(md) {
            &:before {
                content: '';
                display: block;
                position: absolute;
                z-index: 0;
                right: 0;
                top: 0;
                width: 50%;
                height: 100%;
                background: theme-color('light');
            }
        }
    }
}

.contact-form {
    &__form-group {
        position: relative;
        border-bottom: solid 2px theme-color('primary');

        label {
            position: absolute;
            z-index: 2;
            top: 0;
            padding-left: 0.75rem;
            margin: 0;
            font-size: 0.75rem;
            // line-height: 1.2;
            text-transform: uppercase;
            color: theme-color('primary');
            opacity: 1;
            transform: translateY(0);
            transition: all 0.2s ease-out;
        }

        input, textarea {
            position: relative;
            z-index: 1;
            display: block;
            width: 100%;
            padding: 1rem 0.75rem 0.5rem 0.75rem;
            border: none;
            color: theme-color('secondary');
            background: transparent;

            transition: all 0.2s ease-out;
        }

        input::placeholder,
        textarea::placeholder {
            color: lighten(theme-color('primary'), 40%);
            text-transform: uppercase;
        }

        input:placeholder-shown,
        textarea:placeholder-shown {
            padding: 0.75rem;
        }

        input:placeholder-shown + label,
        textarea:placeholder-shown + label {
            opacity: 0;
            transform: translateY(1rem);
        }
        &--error {
            border-color: theme-color('danger');
            label,
            input::placeholder,
            textarea::placeholder {
                color: theme-color('danger');
            }
        }

        .errors {
            position: absolute;
            z-index: 0;
            bottom: 100%;
            left: 0;
            max-width: 100%;
            margin: 0;
            padding: 0.25rem;
            font-size: $font-size-sm;

            color: theme-color-level('danger', $alert-color-level);
            background: theme-color-level('danger', $alert-bg-level);
            border: solid 1px theme-color-level('danger', $alert-border-level);

            opacity: 0;
            transition: all 0.2s ease-out;

            &:after {
                content: '';
                position: absolute;
                top: 100%;
                left: 0.25rem;
                width: 0;
                height: 0;
                border-left: 7px solid transparent;
                border-right: 7px solid transparent;
                border-top: 5px solid theme-color-level('danger', $alert-border-level);
            }
        }

        input:hover + label + .errors,
        textarea:hover + label + .errors,
        input:focus + label + .errors,
        textarea:focus + label + .errors {
            z-index: 5;
            opacity: 1;
        }
    }
    .g-recaptcha {
         > div {
            margin: 0 auto;
        }
    }
}
