.footer-back-to-top {
    position: relative;
    z-index: 10;
    height: 0;
    &__button {
        position: absolute;
        top: -30px;
        right: $grid-gutter-width*1.5;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: url(../imgs/arrow-top.png) center 20px no-repeat;
        background-color: theme-color('primary');
        cursor: pointer;
    }
}
.footer {
    &__left {
        position: relative;
        min-height: 260px;
        overflow: hidden;
        background: url(../imgs/map.png) center center no-repeat;
        &__logo {
            position: absolute;
            z-index: 1;
            width: 320px;
            left: 50%;
            top: 40%;
            margin: -30px 0 0 0;
            img {
                width: 60px;
                height: 60px;
            }
        }
        &:after {
            content: '';
            position: absolute;
            z-index: 0;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            @include gradient-x(color('blue-1'), color('blue-2'));
            opacity: 0.8;
        }
    }
    &__right {
        padding: 0;
        &__top {
            padding: $grid-gutter-width;
            color: white;
            @include gradient-x(lighten(color('blue'), 5%), lighten(color('blue-2'), 0%));
        }
        &__bottom {
            padding: $grid-gutter-width/1.5 $grid-gutter-width;
        }
        @include media-breakpoint-only(sm) {
            &__top,
            &__bottom {
                padding-left: $grid-gutter-width*3;
            }
        }
        @include media-breakpoint-up(xl) {
            &__top,
            &__bottom {
                padding-left: $grid-gutter-width*3;
            }
        }
    }
}
