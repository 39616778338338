.property-list-title {
    margin: $grid-gutter-width*2 0;
}

.property-list-filter {
    &__bg {
        @include gradient-x(color('blue-1'), color('blue-2'));
    }
    select {
        display: block;
        width: 100%;
        padding: $grid-gutter-width $grid-gutter-width/3;
        border: none;
        background: transparent;
        color: white;
        font-size: $font-size-lg;
        option {
            color: initial;
            font-size: initial;
        }
    }
}

.property-list {
    margin: $grid-gutter-width 0;
    .property-box {
        margin: $grid-gutter-width 0;
    }
}

.property-box {
    &__img-item {
        position: relative;
    }
    &__read-more {
        position: absolute;
        bottom: 0;
        right: 0;
        padding: $grid-gutter-width/3 $grid-gutter-width/2;
        color: white;
        @include gradient-x(color('blue-1'), color('blue-2'));
    }
}
