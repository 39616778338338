.property-detail-header {
    position: relative;
    height: 620px;
    margin-top: -123px;

    background: center center no-repeat;
    background-size: cover;

    &__video_top_link {
        position: absolute;
        top: 123px + $grid-gutter-width;
        right: $grid-gutter-width;

        color: white;
        &:hover {
            color: white;
        }
    }

    &__bar {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;

        background: theme-color('primary');
        background: rgba(black, 0.4);
        color: white;

        h1 {
            font-size: $font-size-lg;
            @include media-breakpoint-up(sm) {
                font-size: $h2-font-size;
            }
            @include media-breakpoint-up(md) {
                font-size: $h1-font-size;
            }
        }
        a {
            color: white;
            &:hover {
                color: white;
            }
        }
    }
    &__links {
        a {
            display: block;
            font-weight: bold;
            font-size: 0.8em;
            text-transform: uppercase;
            text-decoration: none;
            padding: $grid-gutter-width/3;
            &:hover {
                background: darken(theme-color('primary'), 5%);
                background: rgba(black, 0.4);
            }
        }
    }
    &__video_link,
    &__gallery_link {
        font-size: $font-size-lg;
        @include media-breakpoint-up(md) {
            font-size: $h1-font-size;
        }
    }
}
.property-detail {
    &--section {
        position: relative;
        margin: 3rem 0;
    }
    &__description {
        &__icons {
            span {
                font-size: $font-size-lg;
                @include media-breakpoint-up(sm) {
                    font-size: $h1-font-size;
                }
            }
        }
    }
    &__amenities,
    &__gallery,
    &__other {
        padding-bottom: 2rem;
        &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 60px;
            height: 4px;
            background: theme-color('primary');
        }
    }
    &__gallery {
        .row {
            margin-right: -($grid-gutter-width/3);
            margin-left: -($grid-gutter-width/3);

            > .col,
            > [class*="col-"] {
                padding: $grid-gutter-width/3;
            }
        }
    }
    &__video {
        position: relative;
        width: 100%;
        padding-bottom: 56.25%;
        video,
        object,
        embed,
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;
        }
    }
}
