$fa-font-path: "../fontawesome/webfonts" !default;
@import "../fontawesome/scss/fontawesome";
@import "../fontawesome/scss/brands";
// @import "../fontawesome/scss/regular";
@import "../fontawesome/scss/solid";

@import "bootstrap";


body {
    overflow-y: scroll;
    // background: $light;
}

#container {
    margin-bottom: 6rem;
}

//
// Icons
//

[class^="icon-"]:before,
[class*=" icon-"]:before {
    color: inherit;
}

//
// New property section
//

.featured-property-section {
    margin-top: 6rem;
    padding: 3rem 0 6rem 0;
}


@import "header";
@import "footer";
@import "home";
@import "static_page";
@import "property_list";
@import "property_detail";
@import "contacts";
