.home-header {
    // height: 700px;
    // height: 90vh;
    background: center center no-repeat;
    &__col-left {
        padding: 0;
        border-right: solid 1px white;
    }
    &__col-right {
        padding: 0;
        &__top {
            padding: $grid-gutter-width*4 $grid-gutter-width/2 $grid-gutter-width*2 $grid-gutter-width/2;
            color: white;
            p,
            h1 {
                text-shadow: 1px 1px 2px #000;
            }
            p {
                font-size: $font-size-sm;
            }
            h1 {
                font-size: $h2-font-size;
            }
            .btn {
                margin-top: $grid-gutter-width;
                box-shadow: 1px 1px 2px #000;
            }

            @include media-breakpoint-up(md) {
                // padding: 200px $grid-gutter-width*2 $grid-gutter-width*2 $grid-gutter-width*2;
                p {
                    font-size: $font-size-base;
                }
                h1 {
                    font-size: $display3-size;
                }
            }
            @include media-breakpoint-up(lg) {
                padding: 300px $grid-gutter-width*2 $grid-gutter-width*2 $grid-gutter-width*2;
            }
        }
        &__bottom {
            padding: $grid-gutter-width $grid-gutter-width/2;
            color: white;
            @include gradient-x(color('blue-1'), color('blue-2'));

            @include media-breakpoint-up(md) {
                padding-left: $grid-gutter-width*2;
                padding-right: $grid-gutter-width*2;
            }
        }
        &--max-width {
            max-width: 710px;
        }
    }
    &__left_bar {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 12px;
        width: 100%;
        @include gradient-x(color('blue-1'), color('blue-2'));
    }
}

.home-services {
    &__item {
        margin-top: $grid-gutter-width*0.5;
        margin-bottom: $grid-gutter-width*1.5;
        @include media-breakpoint-up(lg) {
            &:nth-child(odd) {
                margin-top: -$grid-gutter-width*4.5;
            }
        }
    }
    &__img_col {
        position: relative;
        @include media-breakpoint-up(sm) {
            padding-left: $grid-gutter-width;
            padding-bottom: $grid-gutter-width/3;
            .order-sm-last & {
                padding-left: 0;
                padding-right: $grid-gutter-width;
            }
        }
    }
    &__text_col {
        margin-top: $grid-gutter-width/2;
        text-align: center;
        @include media-breakpoint-up(sm) {
            margin-top: 0;
            text-align: left;
            &.even {
                text-align: right;
            }
        }
    }
    &__icon_circle {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 60px;
        height: 60px;
        background: $primary;
        line-height: 1;
        .order-sm-last & {
            left: auto;
            right: 0;
        }
        i {
            font-size: 36px;
            color: white;
        }
        @media (min-width: map-get($container-max-widths, 'sm') / 1.2) {
            width: 100px;
            height: 100px;
            i {
                font-size: 60px;
            }
        }
    }
    &__item--help {
        a {
            position: relative;
            display: block;
            padding: $grid-gutter-width/2 (36px + $grid-gutter-width/2);
            line-height: 36px;
            border-radius: 36px + $grid-gutter-width;
            font-size: 26px;
            font-weight: lighter;
            text-decoration: none;
            text-align: center;
            background: $primary;
            color: white;
            i {
                position: absolute;
                top: 50%;
                left: $grid-gutter-width/2;
                font-size: 36px;
                margin-top: -(36px/2);
            }
            &:hover {
                text-decoration: none;
                background: $link-hover-color;
                color: white;
            }
            @include media-breakpoint-up(md) {
                padding: $grid-gutter-width/2 (50px + $grid-gutter-width);
                line-height: 50px;
                border-radius: 50px + $grid-gutter-width;
                font-size: 40px;
                i {
                    left: $grid-gutter-width/2;
                    font-size: 50px;
                    margin-top: -(50px/2);
                }
            }
        }
    }
}
