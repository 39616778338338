body {
    padding-top: 60px;
    @include media-breakpoint-up(lg) {
        padding-top: 0;
    }
}
.header {
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    .col-left,
    .col-right {
        padding-left: 0;
        padding-right: 0;
    }
    .col-left {
        .logo-wrapper {
            padding: $grid-gutter-width/3 $grid-gutter-width/2;
        }
        .logo {
            height: 60px - ($grid-gutter-width/3*2);
        }
        @include media-breakpoint-up(lg) {
            .logo-wrapper {
                padding: $grid-gutter-width;
            }
            .logo {
                float: right;
                height: 60px;
            }
        }
    }
    .col-right {
        min-height: 0;
        background: theme-color('primary');
        @include media-breakpoint-up(lg) {
            padding-left: $grid-gutter-width;
            background: rgba(0, 0, 0, 0.20);
        }
    }
    &__top-line {
        padding: 0 $grid-gutter-width/2;
        text-align: right;
        color: white;
        a,
        a:hover,
        a:focus{
            color: white;
            text-decoration: none;
        }
        span {
            text-decoration: underline;
        }
        &__language-links {
            font-size: $font-size-base;
            font-weight: bold;
            text-transform: uppercase;
            a,
            span {
                margin-left: 5px;
            }
        }
        &__social {
            font-size: $font-size-base*2;
        }

        @include media-breakpoint-up(lg) {
            max-width: 740px;
            &__language-links {
                font-size: $font-size-base*0.8;
            }
        }
    }
    .navbar-nav {
        border-top: solid 1px white;
        border-bottom: solid 1px white;
        a {
            display: block;
            position: relative;
            padding: $grid-gutter-width/2 $grid-gutter-width;
            font-size: $font-size-base;
            font-weight: bold;
            color: white;
            text-transform: uppercase;
            text-decoration: none;
            &:after {
                content: '';
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 2px;
            }
        }
        a:hover,
        a.active {
            background: rgba(0, 0, 0, 0.20);
        }
        a.active:after {
            @include gradient-x($blue-1, $blue-2);
        }
        @include media-breakpoint-up(lg) {
            padding: 0 $grid-gutter-width/2 0 0;
            border-bottom: none;
            a {
                padding: 10px 8px;
                font-size: $font-size-base * 0.8;
            }
        }
        @include media-breakpoint-up(xl) {
            a {
                padding: 10px 10px;
            }
        }
    }
}

.header-img-section {
    height: 320px;
    background: center center no-repeat;
}
